/**@jsx jsx */
import { jsx, Box, Flex, Grid, Styled } from 'theme-ui';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import StudentThumbnail from '../components/student-thumbnail';
import Button from '../common/button';
import SEO from '../components/seo';
import Cities from '../components/cities'

export const query = graphql`
  query {
    allStudent(filter: { frontmatter: { location: { eq: "Philadelphia" }}}){
      edges {
        node {
          id
          frontmatter {
            student_name
            headshot
          }
          slug
        }
      }
    }
  }
`;

const HireResilient = ({ data }) => {
  return (
    <Layout>
      <SEO title="Roster" />
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background2',
            color: 'text4',
            marginBottom: '20px',
            width: '100%',
            backgroundImage: `radial-gradient(dimgrey 20%, transparent 0), radial-gradient(dimgrey 20%, transparent 0)`,
            backgroundSize: '5px 5px',
            backgroundPosition: '0 0, 200px 200px',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              p: 30,
              textAlign: 'center',
            }}
          >
            <h1>DIGITAL DEMO DAY</h1>
            <p sx={{ maxWidth: '600px' }}>
              Meet the latest cohort of Resilient Coders, composed of talented software engineers from Boston and Philly.  Listen to their 1-minute pitches, and then join them in smaller, more intimate breakout sessions, to catch their project demos and ask questions. Projects are independently-built full-stack applications that showcase students' aptitudes. If you're hiring engineers, you'll want to be there.
            </p>
            <Button
              text={'RSVP'}
              href={
                'https://live.remo.co/e/2023b-demo-day/register'
              }
            />
          </Flex>
        </Box>
        <Cities />
        {/* <Box>
          <Flex sx={{ flexDirection: 'column' }}>
            <h2>Demo Day Agenda</h2>
            <Flex>
              <Box sx={{ border: '1px solid black', height: 300, width: 300 }}>
                FANCY DEMO DAY DESCRIPTION
              </Box>
              <Box sx={{ border: '1px solid black', height: 300, width: 300 }}>
                PICTURE OF STUDENT
              </Box>
            </Flex>
          </Flex>
        </Box> */}
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Grid sx={{ maxWidth: '1150px' }} columns={[2, 2, 3]}>
            {data.allStudent.edges.map(({ node: { slug, frontmatter } }) => {
              return (
                <StudentThumbnail
                  key={slug}
                  url={`/roster${slug}`}
                  student_name={frontmatter.student_name}
                  image={frontmatter.headshot}
                />
              );
            })}
          </Grid>
        </Flex>
        <Button
          text={'RSVP'}
          href={
                'https://live.remo.co/e/2023b-demo-day/register'
          }
        />
      </Flex>
    </Layout>
  );
};

export default HireResilient;
