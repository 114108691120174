/**@jsx jsx */
import { jsx, Flex, Image, Box } from 'theme-ui';
import { Link, navigate } from 'gatsby';

const StudentThumbnail = ({ student_name, url, image }) => {
  const cloudinaryUrl =
        //    'https://res.cloudinary.com/resilientcoders-org/image/upload';
        'https://res.cloudinary.com/dktv8cf8b/image/upload';        
  const faceDetectionUrl = 'c_fill,g_face,h_200,w_255/dpr_2.0,f_auto';
  const completeImageUrl = `${cloudinaryUrl}/${faceDetectionUrl}${image}`;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'background2',
          overflow: 'hidden',
        }}
      >
        <Image
          sx={{
            transition: 'opacity 1s',
            width: '100%',
            opacity: '.7',
            '&:hover': {
              opacity: '1',
              cursor: 'pointer',
            },
          }}
          alt={student_name}
          onClick={() => navigate(url)}
          src={completeImageUrl}
        />
      </Box>
      <span
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background2',
          height: 40,
          width: '100%',
          color: 'white',
          // position: 'relative',
          // bottom: 50,
        }}
      >
        {student_name}
      </span>
    </Flex>
  );
};

export default StudentThumbnail;
